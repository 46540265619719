import request from '@/utils/request'

export function fetch_loiers(api, page) {
    return request({
        url: api,
        method: 'get',
        params: {
            page: page
        }
    })
}

export function subscribe_category(data) {
    return request({
        url: 'user/category/subscribe',
        method: 'post',
        data
    })
}

export function fetchCategoryInfoById(id) {
    return request({
        url: `user/category/info/${id}`,
        method: 'get'
    })
}