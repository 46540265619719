import request from '@/utils/request'


export function fetch_loi_list(api, page) {
    return request({
        url: api,
        method: 'get',
        params: {
            page: page
        }
    })
}

export function fetch_loi(loi_id) {
    return request({
        url: `lois/${loi_id}`,
        method: 'get'
    })
}

export function fetch_loi_liked(page) {
    return request({
        url: "user/mylikes",
        method: 'get',
        params: {
            page: page
        }
    })
}


export function fetch_loi_liked_v2(page) {
    return request({
        url: "user/mylikesV2",
        method: 'get',
        params: {
            page: page
        }
    })
}

export function fetch_loi_downloaded(page) {
    return request({
        url: "user/mydownloaded",
        method: 'get',
        params: {
            page: page
        }
    })
}


export function like_loi(data) {
    return request({
        url: 'user/loi/like',
        method: 'post',
        data
    })
}

export function get_mylikes(data) {
    return request({
        url: 'user/loi/mylikes',
        method: 'post',
        data
    })
}

export function fetchLoiListByCategoryId(id, page) {
    return request({
        url: "user/library/loier/id",
        method: 'get',
        params: {
            category_id: id,
            page: page
        }
    })
}

export function fetchLoiListByTagId(id, page) {
    return request({
        url: "user/library/tag/id",
        method: 'get',
        params: {
            tag_id: id,
            page: page
        }
    })
}

export function fetchLoiDownloadLink(loi_id) {
    return request({
        url: `user/loi/download/${loi_id}`,
        method: "get"
    })
}

export function fetchLoiDownloadLinkV6(loi_id) {
    return request({
        url: `user/loi/download_v6/${loi_id}`,
        method: "get"
    })
}

export function fetchLoiOnlinePlay(loi_id) {
    return request({
        url: `user/loi/online_play_v2/${loi_id}`,
        method: "get"
    })
}