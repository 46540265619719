<template>
<div>
  <template v-if="theme === 1">

    <v-btn :loading="op_btn_loading" :disabled="op_btn_loading" block color="#fb7299" class="white--text mr-2" @click="open_onlinePlay_dialog">
      <v-icon>mdi-play</v-icon>在线播放
    </v-btn>
  </template>
  <template v-else>
    <v-btn
        :loading="op_btn_loading"
        :disabled="op_btn_loading"
        color="primary"
        block
        class="dl__onlinePlay-button"
        @click="open_onlinePlay_dialog"
    ><v-icon>mdi-play</v-icon>播放
    </v-btn>
  </template>

  <!--op dialog-->
  <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="onlinePlayDialog"
      width="720"
      persistent
      no-click-animation
  >
    <v-card>
      <v-card-title class="text-h5 pt-6">
        <v-btn fab small class="mr-5" @click="close_onlinePlay_dialog">
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
       <div class="mr-2">在线播放</div>
      </v-card-title>

      <v-card-text class="mt-2">
        <v-tabs v-model="numberList_tab" grow>
          <v-tab><v-badge
              color="primary"
              :content="videoItems_num || '空'"
              :offset-x="-1"
          >
            视频
          </v-badge></v-tab>
          <v-tab><v-badge
              color="primary"
              :content="imgItems_num || '空'"
              :offset-x="-1"
          >
            图包
          </v-badge></v-tab>
        </v-tabs>

        <v-tabs-items v-model="numberList_tab">
          <v-tab-item>
            <template v-if="videoItems_num > 0">
              <div style="position: absolute; top: 35%; left: 50%; transform: translate(-50%, -50%);">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
                <p class="mt-10 ml-n3">视频加载中...</p>
              </div>
              <div style="position: relative; padding-top: 56.25%;" class="mt-4">


                <iframe
                    :key="onlinePlay_video_current"
                    :src="onlinePlay_video_current"
                    style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                ></iframe>
              </div>



              <div class="mt-4">
                <div class="subtitle-1 mb-3">{{loi_title}}</div>

                <!--ad-->
                <!--<div class="subtitle-2 my-3">-->
                <!--  <v-chip small label class="mr-1" color="primary">推广</v-chip>广告文本 (<a href="/">链接</a>)-->
                <!--</div>-->

                <div>选集</div>

                <v-btn-toggle
                    v-model="numberList_text"
                    color="primary"
                    group
                    mandatory
                    class="my-btn-toggle"
                >
                  <v-btn :value="`第${index}集`" v-for="index in videoItems_num" :key="index" @click="onlinePlay_video_current = videoItems[index-1]">
                    第{{index}}集
                  </v-btn>
                </v-btn-toggle>
              </div>
            </template>
            <template v-else>
              <div class="my-10">
                <v-img
                    aspect-ratio="1"
                    width="256"
                    height="214"
                    class="mx-auto mb-5"
                    src="https://static.moegoat.com/images/nodata-box.png"
                ></v-img>
                <div class="subtitle-1 grey--text text--darken-1 text-center">本资源没有视频哦~</div>
              </div>
            </template>
          </v-tab-item>
          <v-tab-item>

            <v-row v-if="imgItems_num > 0">
              <v-col
                  v-for="(image, imageIndex) in imgItems"
                  :key="imageIndex"
                  class="d-flex child-flex"
                  cols="6"
                  @click="updateImgIndex(imageIndex)"
              >
                <v-img
                    :src="image"
                    :lazy-src="image"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>

            <div class="my-10" v-else>
              <v-img
                  aspect-ratio="1"
                  width="256"
                  height="214"
                  class="mx-auto mb-5"
                  src="https://static.moegoat.com/images/nodata-box.png"
              ></v-img>
              <div class="subtitle-1 grey--text text--darken-1 text-center">本资源没有图包哦~</div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

    </v-card>

  </v-dialog>

  <!-- 播放无权限提示模态 -->
  <v-dialog v-model="vipUpgrade_dialog" max-width="400">
    <v-card>
      <v-container class="text-center pb-7 pt-6">
        <v-img
            width="180px"
            height="150px"
            contain
            class="mx-auto mt-3"
            :src="vipList[vip_requirement].img"
        ></v-img>
        <div class="title font-weight-bold mt-6" :style="vipColor">
          <div class="mb-4">🚌 巴士会SVIP专享</div>
          <v-chip class="font-weight-light">
            🔐
            {{
              `巴士会SVIP 且${vipList[vip_requirement].level.substring(2)}`
            }}<span v-if="vip_requirement !== 4">及以上</span>可在线观看
          </v-chip
          >
        </div>

        <v-card-actions class="d-flex justify-center mt-7 px-11 pb-0">
          <v-btn
              height="40"
              color="primary"
              rounded
              block
              to="/vip?svip=1"
          >
            <v-icon left dark>mdi-chevron-double-up</v-icon>
            升级为巴士会SVIP
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import {fetchLoiOnlinePlay} from "@/api/loi";
import {vipList} from "@/config";
import msg from "@/utils/snackbar";

export default {
  name:'LoiOP',
  props: {
    theme: {
      type: Number,
      required: false,
    },
    loi_id: {
      type: Number,
      required: true,
    },
    loi_title: {
      type: String,
      required: true,
    },
    online_play:{
      type: Number,
      required: true
    },
    vip_requirement: {
      type: Number,
      required: true,
    }
  },
  data() {
    return{
      //op
      op_btn_loading:false,
      onlinePlayDialog:false,
      onlinePlay_video_current:'',
      numberList_tab:0,
      numberList_text: '第1集',
      videoItems:[],
      videoItems_num:0,
      imgItems_num:0,
      imgItems:[],

      vipLevel: [
        {
          color: "#4caf50",
          icon: "star",
        },
        {
          color: "#4caf50",
          icon: "star",
        },
        {
          color: "#2196f3",
          icon: "star-four-points",
        },
        {
          color: "#4e2ecc",
          icon: "diamond-stone",
        },
        {
          color: "#FF5252",
          icon: "crown",
        },
      ],

      vipColor: {
        color: "#f06868",
      },

      vipList: vipList,

      vipUpgrade_dialog: false,
    }
  },
  methods:{
    async open_onlinePlay_dialog(){
      if (!this.$store.getters.token) {
        this.$store.commit("showAccountDialog");
        return msg("请先登陆哦~", "primary", "information");
      }

      if (this.online_play === 1){

        this.op_btn_loading = true

        try {
          const online_play_data = await fetchLoiOnlinePlay(this.loi_id);

          this.videoItems_num = online_play_data.videos_count
          this.videoItems = online_play_data.result.videos


          this.imgItems_num =  online_play_data.images_count
          this.imgItems = online_play_data.result.images
          this.updateImgItems(online_play_data.result.images)


          if (online_play_data.videos_count > 0){
            this.onlinePlay_video_current = this.videoItems[0]
          }else {
            this.numberList_tab = 1
          }

          this.op_btn_loading = false

          this.onlinePlayDialog = true
        }catch (error) {
          // console.log(error)
          //用户被禁用
          if (
              error.err_code === -2 ||
              error.err_code === 40040 ||
              error.err_code === 60006
          ) {
            this.download_loading = false;
            this.op_btn_loading = false
            return msg(error.err_msg, "error", "alert-circle", false);
          } else if (
              error.err_code === 60001 ||
              error.err_code === 60002 ||
              error.err_code === 60003 ||
              error.err_code === 60007 ||
              error.err_code === 60008
          ) {
            //提示会员升级
            this.vipUpgrade_dialog = true;
          } else {
            this.op_btn_loading = false
            return msg("服务器错误，请联系客服", "error", "alert-circle", false);
          }
        }

        this.op_btn_loading = false


      }else {
        return msg("未开放在线播放", "primary", "information");
      }
    },

    close_onlinePlay_dialog(){
      this.numberList_text = '第1集'

      this.onlinePlay_video_current = ''

      this.updateImgItems([]);

      this.onlinePlayDialog = false
    },
    updateImgItems(items) {
      this.$store.dispatch('updateImgItems', items);
    },
    updateImgIndex(index) {
      this.$store.dispatch('updateImgIndex', index);
    },
  }
}
</script>
<style scoped>
.dl__onlinePlay-button {
  border-radius: 4px 0 0 4px;
}

.my-btn-toggle {
  display: flex;
  flex-wrap: wrap;
}

.my-btn-toggle .v-btn {
  min-width: calc(100% / 5); /* 确保按钮的最小宽度，使得每行最多只能容纳6个按钮 */
  box-sizing: border-box;
}
</style>
