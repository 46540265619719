import request from '@/utils/request'

export function fetch_comments(page, sort, loi_id) {
    return request({
        url: 'comment/' + loi_id,
        method: 'get',
        params: {
            page: page,
            sort: sort
        }
    })
}


export function comment(data) {
    return request({
        url: 'comment',
        method: 'post',
        data
    })
}

export function like_comment(data) {
    return request({
        url: 'user/comment/like',
        method: 'post',
        data
    })
}

export function comment_has_liked(data) {
    return request({
        url: 'user/comment/has_liked',
        method: 'post',
        data
    })
}


export function delete_comment(data) {
    return request({
        url: 'user/comment/delete',
        method: 'post',
        data
    })
}