<template>
  <div>
    <slot :show="show">
      <v-btn text @click="feedback_dialog = true" class="red--text">
        <v-icon left>mdi-alert</v-icon>
        {{feedback_text}}
      </v-btn>
    </slot>
    <!-- 反馈模态 -->
    <v-dialog v-model="feedback_dialog" max-width="380">
      <v-card>
        <v-card-title>发送反馈</v-card-title>
        <v-list>
          <v-list-item-group v-model="feedback_index">
            <v-list-item color="bpink" v-for="(item, i) in feedback_list" :key="i">
              <v-list-item-icon :class="{'py-3':i !== feedback_index}">
                <v-icon v-show="i === feedback_index">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <textarea
          v-model="feedback_content"
          class="mb-2"
          autofocus
          required
          :placeholder="feedback_list[feedback_index].placeholder"
          rows="5"
          style="border: none; box-shadow: none; box-sizing: border-box; color: rgb(33, 33, 33); flex-grow: 1; font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif; height: auto; max-width: 100%; outline: none; padding: 18px 16px 0px; resize: none; width: 100%;"
        ></textarea>
        <!-- ss -->

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="feedback_dialog = false" class="mr-2">取消</v-btn>
          <v-btn
            text
            color="bpink"
            class="white--text"
            :loading="feedback_loading"
            :disabled="feedback_loading"
            @click="handle_feedback"
          >
            <v-icon left class="ml-1">mdi-send</v-icon>提交
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { send_feedback } from "@/api/feedback.js";
import msg from "@/utils/snackbar.js"; //消息提示组件

export default {
  name: "FeedbackDialog",
  props: {
    text: {
      type: String,
      required: false,
      default: "报告"
    },
    list: {
      type: Array,
      required: true
    },
    loi_id: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      // 反馈
      feedback_index: 0,
      feedback_loading: false,
      feedback_dialog: false,
      feedback_content: "",
      feedback_list: this.list,

      id: this.loi_id,
      feedback_text: this.text
    };
  },
  methods: {
    show() {
      this.feedback_dialog = true;
    },
    async handle_feedback() {
      if (!this.$store.getters.token) {
        this.$store.commit("showAccountDialog");
        return msg('请先登陆后提交反馈~','primary','information');
      }

      this.feedback_loading = true;

      try {
        let response = await send_feedback({
          loi_id: this.id,
          type: this.feedback_list[this.feedback_index].id,
          feedback_content: this.feedback_content
        });

        this.feedback_index = 0;
        this.feedback_content = "";

        msg(response.data,'success','check');

      } catch (error) {
        // window.console.log(error);
        msg('请求异常','error','alert-circle',false);
      }

      this.feedback_loading = false;
    }
  }
};
</script>

<style>
</style>