import CryptoJS from "crypto-js";

const KEY = '7811595744111520';
const IV = 'RgaXNyE5aWckBwI7';


export function decrypt(str) {
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var decrypted = CryptoJS.AES.decrypt(str, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
}