<template>
  <div>
    <slot :handleLoiLike="handleLoiLike">
      <template v-if="theme === 0">
        <v-hover v-slot:default="{ hover }">
          <div>
            <v-btn
              icon
              :loading="like_loading"
              :disabled="like_loading"
              @click="handleLoiLike"
              :color="hover ? 'like':'blue-grey darken-3'"
            >
              <v-icon
                :color="hover || liked ? 'like':'blue-grey darken-3'"
              >{{liked ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
            </v-btn>
            <span
              class="d-block body-2 text-center"
              style="transform:translateY(8px)"
              :style="{'color': hover || liked ? '#ED4956':''}"
            >{{numConvert(like_num)}}</span>
          </div>
        </v-hover>
      </template>
      <template v-else>
        <v-btn
          text
          icon
          color="like"
          class="ml-4"
          @click="handleLoiLike"
          :loading="like_loading"
          :disabled="like_loading"
        >
          <v-icon>{{liked ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
        </v-btn>
      </template>
    </slot>
  </div>
</template>

<script>
import { like_loi } from "@/api/loi.js";
import msg from "@/utils/snackbar.js"; //消息提示组件

export default {
  name: "LoiLiker",
  props: {
    theme: {
      type: Number,
      required: false,
      default: 1
    },
    loi_id: {
      type: Number,
      required: true
    },
    like_count: {
      type: Number,
      required: false,
      default: 0
    },
    is_liked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      like_loading: false,
      like_num: this.like_count,
      liked: this.is_liked
    };
  },
  methods: {
    async handleLoiLike() {
      if (!this.$store.getters.token) {
        this.$store.commit("showAccountDialog");
        return msg('请先登陆哦~','primary','information',false);
      }

      this.like_loading = true;

      let response = await like_loi({ loi_id: this.loi_id });

      if (response.data == "liked") {
        this.liked = true;
        this.like_num++;
        this.$emit("like_increase");
        msg(`已保存至"我的喜欢"`,'success','check',false);
      } else {
        this.liked = false;
        this.like_num--;
        this.$emit("like_decrease");
        msg(`已从"我的喜欢"中移除`,'warning','information',false);
      }

      this.like_loading = false;
    },
    numConvert(num) {
      if (num >= 10000) {
        num = Math.round(num / 1000) / 10 + "W";
      } else if (num >= 1000) {
        num = Math.round(num / 100) / 10 + "K";
      }
      return num;
    }
  }
};
</script>

<style>
</style>