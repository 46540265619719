<template>
  <div>
    <slot :handleDownload="handleDownload">
      <!-- 封面 -->
      <template v-if="btn_theme === 0">
        <v-hover v-slot:default="{ hover }">
          <div>
            <v-btn
                :color="hover ? 'primary' : 'blue-grey darken-3'"
                icon
                :loading="download_loading"
                :disabled="download_loading"
                @click="handleDownload"
            >
              <v-icon
                  :color="
                  hover || is_downloaded
                    ? 'light-blue darken-1'
                    : 'blue-grey darken-3'
                "
                  v-text="download_icon"
              ></v-icon>
            </v-btn>

            <span
                :class="{ 'light-blue--text': hover }"
                class="d-block body-2 text-center"
                style="transform: translateY(8px)"
                :style="{ color: hover || is_downloaded ? '#039BE5' : '' }"
            >{{ numConvert(loiData.download_count) }}</span
            >
          </div>
        </v-hover>
      </template>
      <!-- 内页 -->
      <template v-else-if="btn_theme === 1">
        <v-btn
            :tile="tile"
            class="px-6"
            color="primary"
            @click="handleDownload"
            :loading="download_loading"
            :disabled="download_loading"
            :block="is_block"
        >
          <v-icon class="mr-1">mdi-download</v-icon>
          获取
        </v-btn>
      </template>

      <!--附带在线播放-->
      <template v-else-if="btn_theme === 2">
            <v-btn
                :class="{'dl__get-button px-3':!$vuetify.breakpoint.xsOnly}"
                color="primary"
                @click="handleDownload"
                :loading="download_loading"
                :disabled="download_loading"
                block
            >
              <v-icon class="mr-1">mdi-download</v-icon>
             <span v-if="$vuetify.breakpoint.xsOnly">获取</span>
            </v-btn>
      </template>
    </slot>

    <!-- 排队模态 -->
    <v-dialog v-model="download_queue" max-width="400">
      <v-card>
        <v-container class="text-center py-8">
          <v-progress-circular
              :rotate="360"
              :size="95"
              :width="15"
              class="title font-weight-light mb-11 mt-8"
              :value="download_queue_value"
              color="primary"
          >{{ download_queue_value }}
          </v-progress-circular
          >
          <div class="subtitle-1 font-weight-light mt-3">
            <v-progress-circular
                :size="15"
                :width="2"
                class="mr-4"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <span
                v-text="download_queue_text"
                :style="download_queue_textColor"
            ></span>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- 下载模态 -->
    <v-dialog v-model="download_dialog" max-width="470">
      <v-card>
        <div class="container">
          <v-row no-gutters>
            <v-card-title class="headline">🚀 下载</v-card-title>
            <v-spacer></v-spacer>
            <span class="align-self-center mr-5">
              <v-icon class="mb-1 mr-1">mdi-flash-red-eye</v-icon>
              <countTo
                  ref="download_count"
                  :autoplay="false"
                  :startVal="1"
                  :endVal="rdN(2, 155)"
                  :duration="rdN(4000, 25000)"
              ></countTo
              >人正在下载
            </span>
          </v-row>

          <v-alert
              border="left"
              :color="vipLevel[this.$store.getters.vip_id || 0].color"
              :icon="'mdi-' + vipLevel[this.$store.getters.vip_id || 0].icon"
              class="mx-4 mt-1 mb-1"
              text
          >正在享用
            <span class="font-weight-bold">{{
                this.vipList[this.$store.getters.vip_id || 1].level.substr(2)
              }}</span>
            专属下载特权
          </v-alert
          >

          <div class="mx-5 mb-2 mt-2">
            <!-- 本地下载地址区域 -->
            <v-row no-gutters v-for="server in sv_list" :key="server.sv_url">
              <template v-if="svDecrypt(server.sv_url).includes('http')">
                <v-col cols="10">
                  <v-tooltip bottom nudge-top="25" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="svDecrypt(server.sv_url)"
                          prepend-inner-icon="mdi-link-box-variant"
                          readonly
                          color="primary"
                          onclick="this.select()"
                          :hint="server.sv_name + '  -  ' + server.sv_desc"
                          persistent-hint
                          v-clipboard:copy="svDecrypt(server.sv_url)"
                          v-clipboard:success="
                        () => {
                          onCopy(`下载地址已复制`);
                        }
                      "
                          v-clipboard:error="onError"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <span>复制下载地址到剪切板</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" class="align-self-center pl-2 pb-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          outlined
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="openUrl(svDecrypt(server.sv_url))"
                      >
                        <v-icon :size="21">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>本地下载</span>
                  </v-tooltip>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12">
                  <v-text-field prepend-inner-icon="mdi-link-box-variant" readonly color="primary" persistent-hint
                                :hint="server.sv_name + '  -  ' + server.sv_desc"
                                :value="svDecrypt(server.sv_url)"></v-text-field>
                </v-col>
              </template>
            </v-row>
            <!--  -->

            <v-row no-gutters v-if="baidupan_link">
              <v-col cols="10">
                <v-text-field
                    v-model="baidupan_link"
                    prepend-inner-icon="mdi-link-box-variant"
                    readonly
                    color="primary"
                    onclick="this.select()"
                    :hint="`网盘地址 （提取码：${baidupan_key}）下载后请更改拓展名为.7z 后解压`"
                    persistent-hint
                    @click="openUrl(baidupan_link)"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="align-self-center pl-2 pb-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        v-clipboard:copy="baidupan_key"
                        v-clipboard:success="
                        () => {
                          onCopy(`提取码 ( ${baidupan_key} ) 已复制`);
                        }
                      "
                        v-clipboard:error="onError"
                    >
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>复制提取码</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-text-field
                v-model="loiData.open_key"
                prepend-inner-icon="mdi-file-key"
                readonly
                onclick="this.select()"
                hint="解压密码"
                persistent-hint
                v-clipboard:copy="open_key"
                v-clipboard:success="
                () => {
                  onCopy('解压密码已复制');
                }
              "
                v-clipboard:error="onError"
            >


              <template v-slot:append>
                <span @click.stop="onButtonClick" class="caption" style="cursor: pointer;">
                  下载遇到问题？</span>
              </template>
            </v-text-field>


            <v-alert
                v-if="open_key == 'loix.cc'"
                border="left"
                colored-border
                type="info"
                elevation="2"
                dense
                class="mt-3"
            >
              解压密码为：loix.cc
            </v-alert>


            <!--ad-->
            <!--<div class="subtitle-2 mt-3">-->
            <!--  <v-chip small label class="mr-1" color="primary">推广</v-chip>广告文本 (<a href="/">链接</a>)-->
            <!--</div>-->

          </div>

          <v-card-actions>
            <v-btn color="primary" text @click="download_dialog = false"
            >关闭
            </v-btn
            >

            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                v-clipboard:copy="open_key"
                v-clipboard:success="
                () => {
                  onCopy('解压密码已复制');
                }
              "
                v-clipboard:error="onError"
                text
            >复制解压密码
            </v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- 下载无权限提示模态 -->
    <v-dialog v-model="vipUpgrade_dialog" max-width="400">
      <v-card>
        <v-container class="text-center pb-7 pt-6">
          <v-img
              width="180px"
              height="150px"
              contain
              class="mx-auto mt-3"
              :src="vipList[loiData.vip_requirement].img"
          ></v-img>
          <div class="title font-weight-bold mt-6" :style="vipColor">
            <v-chip class="font-weight-light">
              🔐
              {{
                vipList[loiData.vip_requirement].level.substr(2)
              }}<span v-if="loiData.vip_requirement !== 4">及以上</span>可解锁
            </v-chip
            >
          </div>

          <v-card-actions class="d-flex justify-center mt-7 px-11 pb-0">
            <v-btn
                height="40"
                color="primary"
                rounded
                block
                :to="{
                name: 'vip',
                //query: { plan: loiData.vip_requirement - 1 },
              }"
            >
              <v-icon left dark>mdi-chevron-double-up</v-icon>
              升级会员
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- 帮助弹窗 -->
    <v-dialog
        v-model="help_dialog"
        max-width="470"
    >
      <v-card>
        <v-card-title class="text-h5">
          下载常见问题
        </v-card-title>


        <div class="mb-3">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                1. IOS苹果下载后解压提示"无法用提供的密码解压缩该归档"
              </v-expansion-panel-header>
              <v-expansion-panel-content class="body-1">
                <div class="mb-5">
                  ⚠️注意：IOS下载后的资源请在第三方解压App内打开，直接在浏览器直接打开会导致[密码错误] -> "无法用提供的密码解压缩该归档"而解压失败。
                </div>

                <div class="mb-1">
                  📱  ios上推荐的解压App
                  下载后将下载的资源导入解压App内打开即可解压
                  [App Store选择任意下载即可 🔽]
                </div>

                <v-alert
                    border="left"
                    colored-border
                    color="primary"
                    elevation="2"
                    class="mt-4"
                >
                  <ol>
                    <li>解压专家</li>
                    <li>iZip</li>
                    <li>解压大师</li>
                  </ol>
                </v-alert>

                <v-img contain height="250px" src="http://wimg.973.com/u/c/1203112237185.jpg"></v-img>
                <div class="mt-3">下载后使用任一解压软件打开从网站下载的资源即可</div>


              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-for="q in question_list" :key="q.id">
              <v-expansion-panel-header>
                {{q.id + '. ' + q.q}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{q.a}}
              </v-expansion-panel-content>
            </v-expansion-panel>


          </v-expansion-panels>
        </div>

        <v-card-actions>
          <v-btn text @click="openUrl('https://chatting.page/t9ac8zwwowzq4q2todl6ntrw6m7wpcsu')">联系在线客服</v-btn>

          <v-spacer></v-spacer>

          <v-btn
              text
              @click="help_dialog = false"
          >
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {fetchLoiDownloadLinkV6} from "@/api/loi.js"; //获取下载链接
import {decrypt} from "@/utils/encryption.js"; // decrypt下载链接
import msg from "@/utils/snackbar.js"; //全局提示
import {randomNum} from "@/utils/index.js"; //全局提示
import {vipList} from "@/config.js";
import countTo from "vue-count-to";


export default {
  name: "LoiDownloader",
  components: {countTo},
  props: {
    theme: {
      type: Number,
      required: false,
    },
    tile: {
      type: Boolean,
      required: false,
      default: false,
    },
    download_count: {
      type: Number,
      required: false,
      default: 0,
    },
    loi_id: {
      type: Number,
      required: true,
    },
    vip_requirement: {
      type: Number,
      required: true,
    },
    open_key: {
      type: String,
      default: "loibus.net",
      required: false,
    },
    is_downloaded: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_block: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      btn_theme: this.theme,


      help_dialog:false,



      question_list:[
        {
          id:2,
          q:'解压密码错误',
          a:'解压密码以下载页的为准，每个资源的密码不一定相同，若密码错误，可进入个人中心-[下载记录] 查看对应解压密码。'
        },
        {
          id:3,
          q:'下载地址显示正在上传中',
          a:'资源上传到服务器由于网络延迟原因，可能会延时，耐心等待一段时间后即可下载。'
        },
        {
          id:4,
          q:'下载后显示压缩包已损坏',
          a:'服务器中的资源都经过MD5完整性校验核对后上传，不会存在损坏文件，在您的下载过程中，由于网络不稳定或中断，可能会导致接收文件不完整，建议重新下载。'
        },
        {
          id:5,
          q:'页面/图片显示异常或卡顿，无法正常加载',
          a:'推荐使用最新chrome浏览器以获得完整体验，IOS用户也可以使用safari浏览器。'
        },
        {
          id:6,
          q:'我想求资源',
          a:'发送您想要的资源的[人物名称]+[作品名称]+[主页链接]到在线客服，资源将在获取后第一时间发布。'
        },
        {
          id:7,
          q:'我想投稿资源',
          a:'仅收购无水印未流出的一手资源，发送您要投稿资源的[人物名称]+[作品名称]+[主页链接]+[您的报价]+[联系方式]到在线客服，资源专员将在稍后与您取得联系。'
        },
        {
          id:8,
          q:'无法联系到客服',
          a:'若由于客服繁忙未及时回复，可提交至邮箱 loibus.service@gmail.com 获取帮助。'
        },
        {
          id:9,
          q:'发布页地址',
          a:'loix.cc'
        },
      ],
      //下载
      loiData: {
        loi_id: this.loi_id,
        download_count: this.download_count,
        vip_requirement: this.vip_requirement,
        open_key: this.open_key,
      },

      vipLevel: [
        {
          color: "#4caf50",
          icon: "star",
        },
        {
          color: "#4caf50",
          icon: "star",
        },
        {
          color: "#2196f3",
          icon: "star-four-points",
        },
        {
          color: "#4e2ecc",
          icon: "diamond-stone",
        },
        {
          color: "#FF5252",
          icon: "crown",
        },
      ],

      vipColor: {
        color: "#f06868",
      },

      vipList: vipList,

      vipUpgrade_dialog: false,

      download_dialog: false,
      download_queue: false,
      download_queue_text: "资源获取中...",
      download_queue_textColor: {
        color: "#000",
      },
      download_queue_value: 0,
      download_queue_interval: {},
      download_loading: false,
      sv_list: "",
      baidupan_link: "",
      baidupan_key: "",
    };
  },
  methods: {
    svDecrypt($encryptUrl) {
      return decrypt($encryptUrl);
    },
    //下载
    async handleDownload() {
      if (!this.$store.getters.token) {
        this.$store.commit("showAccountDialog");
        return msg("请先登陆哦~", "primary", "information");
      }
      this.download_loading = true;

      this.download_queue_text = "资源获取中...";
      const vip_node = Math.ceil(Math.random() * 10) + 1;

      try {
        let response = await fetchLoiDownloadLinkV6(this.loiData.loi_id);
        this.sv_list = response.data.sv_list;
        this.baidupan_link = response.data.bdp;
        this.baidupan_key = response.data.bdp_key;

        //window.console.log(response.data);

        this.download_queue = true;
        this.$emit("fetch_end");

        this.download_queue_interval = setInterval(() => {
          const speedList = [10, 30, 40, 50, 60];
          // const speedList = [1000, 1000, 1000, 1000, 1000];
          let step = Math.ceil(
              Math.random() * speedList[this.$store.getters.vip_id]
          );
          // window.console.log("spd" + speedList[this.$store.getters.vip_id]);

          if (this.download_queue_value + step >= 100) {
            this.baidupan_link = decrypt(this.baidupan_link);
            this.baidupan_key = decrypt(this.baidupan_key);

            this.download_queue_value = 100;
            clearInterval(this.download_queue_interval);
            this.download_queue = false;
            this.download_dialog = true;
            this.download_queue_textColor.color = "#263238";

            //下载量+1
            this.loiData.download_count++;
            this.$emit("download_increase");
            setTimeout(() => {
              this.download_queue_value = 0;
              this.$refs.download_count.start();
            }, 500);
          } else {
            if (this.download_queue_value >= 20) {
              this.download_queue_text = "正在选择最优线路...";
            }
            if (this.download_queue_value >= 30) {
              if (this.loiData.vip_requirement === 0) {
                this.download_queue_textColor.color = "#263238";
                this.download_queue_text = `🚌 已分配公共下载节点`;
              } else {
                this.download_queue_textColor.color = "#fb7299";
                this.download_queue_text = `🚀 已分配${this.vipList[
                    this.$store.getters.vip_id
                    ].level
                    .substr(2)
                    .trim()}专属下载节点 [ ${vip_node} ]`;
              }
            }
            if (this.download_queue_value >= 50) {
              this.download_queue_textColor.color = "#263238";
              this.download_queue_text = "🚚 正在获取下载地址...";
            }
            if (this.download_queue_value >= 60) {
              this.download_queue_textColor.color = "#43A047";
              this.download_queue_text = "📦 已获取下载地址...";
            }
            if (this.download_queue_value >= 70) {
              this.download_queue_textColor.color = "#263238";
              this.download_queue_text = "🔑 解析下载地址中...";
            }
            if (this.download_queue_value >= 85) {
              this.download_queue_textColor.color = "#43A047";
              this.download_queue_text = "📥 下载地址解析完成";
            }
            this.download_queue_value += step;
          }
        }, 500);
      } catch (error) {
        //用户被禁用
        if (
            error.err_code === -2 ||
            error.err_code === 40040 ||
            error.err_code === 60006
        ) {
          this.$emit("fetch_end");

          this.download_loading = false;
          return msg(error.err_msg, "error", "alert-circle", false);
        } else if (
            error.err_code === 60001 ||
            error.err_code === 60002 ||
            error.err_code === 60003
        ) {
          //提示会员升级
          this.vipUpgrade_dialog = true;
        } else {
          return msg("服务器错误，请联系客服", "error", "alert-circle", false);
        }
      }
      this.download_loading = false;
      this.$emit("fetch_end");
    },
    onCopy(text) {
      msg(text, "success", "check");
    },
    onError() {
      msg("复制失败，请手动复制", "error", "information");
    },
    numConvert(num) {
      if (num >= 10000) {
        num = Math.round(num / 1000) / 10 + "W";
      } else if (num >= 1000) {
        num = Math.round(num / 100) / 10 + "K";
      }
      return num;
    },
    msg(text, type, icon) {
      msg(text, type, icon);
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    rdN(start, end) {
      return randomNum(start, end);
    },
    onButtonClick(event) {
      // 阻止事件冒泡
      event.stopPropagation();

      document.documentElement.style.overflowY = "scroll";

      this.help_dialog = true
    }
  },
  computed: {
    download_icon() {
      let vip_level = this.$store.getters.vip_id;
      if (this.is_downloaded) {
        return this.loiData.vip_requirement > vip_level
            ? "mdi-download-lock"
            : "mdi-download";
      } else {
        return this.loiData.vip_requirement > vip_level
            ? "mdi-download-lock-outline"
            : "mdi-download-outline";
      }
    }
  }
};
</script>

<style scoped>
.dl__get-button {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
</style>